@tailwind base;
@tailwind components;

@layer components {
  .header_shadow {
    background: #FFF;
    box-shadow: 0px 5px 6.5px 0px rgba(0, 0, 0, 0.05);
  }
  .my-table th, .my-table td {
    padding:0.3rem 0.6rem;
  }
  .menu_h {
    height: calc(100vh - 50px);
  }
}

@tailwind utilities;